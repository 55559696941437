@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  font-family: "PingFangTC", "sans-serif", "Source Sans Pro", "Arial";
  text-decoration: none;
  list-style: none;
  line-height: 1.5em;
  letter-spacing: 0.05em;
  cursor: default; }

input {
  -webkit-appearance: none;
  /* Safari 和 Chrome，常用於iOS下移除內建樣式 */
  -moz-appearance: none;
  /* FireFox */
  appearance: none; }
