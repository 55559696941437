#bubbleMorph .bubble_1,
#bubbleMorph .bubble_2 {
  width: 32vw;
  height: 36vw;
  border-radius: 60% 40% / 70% 40%;
  border-radius: 90% 60% 70% 70% / 30% 70% 70% 90%;
  background-image: linear-gradient(60deg, #2271e5, #109bc8 54%, #0f9ec7);
  overflow: hidden;
  animation: bubble 12s -2s infinite both alternate linear;
  opacity: 0.7; }

@keyframes bubble {
  20% {
    width: 28vw;
    height: 40vw;
    border-radius: 80% 60% 70% 60% / 50% 60% 90% 40%; }
  40% {
    width: 32vw;
    height: 36vw;
    border-radius: 60% 40% 60% 80% / 40% 50% 80% 50%; }
  60% {
    width: 36vw;
    height: 36vw;
    border-radius: 70% 40% 80% 60% / 60% 60% 60% 70%; }
  80% {
    width: 32vw;
    height: 32vw;
    border-radius: 90% 60% 70% 70% / 30% 70% 70% 90%; }
  100% {
    width: 32vw;
    height: 36vw;
    border-radius: 60% 40% / 70% 40%; } }

#bubbleMorph .bubble_2 {
  animation: bubble 12s 5s infinite both alternate linear; }
