@font-face {
  font-family: superFont;
  src: url("../../images/font/superFont.ttf"); }

#landingPage .banner {
  background-image: url("../../images//landingPage//bg.png");
  background-size: 100vw;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 170px;
  margin-top: -100px; }
  #landingPage .banner .app_screenshoot {
    position: relative;
    z-index: 900; }
    #landingPage .banner .app_screenshoot .app_screenshoot_bg img {
      width: 30vw; }
    #landingPage .banner .app_screenshoot .app_screenshoot_map {
      width: 25vw;
      height: 50%;
      position: absolute;
      top: 8.5vh;
      left: 2.5vw;
      padding: 1vw 0;
      overflow: hidden; }
      #landingPage .banner .app_screenshoot .app_screenshoot_map img {
        height: 100%;
        animation: appScroll 5s cubic-bezier(0.3, 0.6, 0.3, 1.1) infinite forwards; }

@keyframes appScroll {
  10% {
    margin-left: 10px; }
  25% {
    margin-left: -40%; }
  50% {
    margin-left: -75%; }
  100% {
    margin-left: 0; } }
  #landingPage .banner .banner_right .banner_slogan {
    margin-left: 7.8vw; }
    #landingPage .banner .banner_right .banner_slogan h1 {
      height: auto;
      color: #fff;
      font-size: 5.6vw;
      font-weight: normal;
      font-family: superFont;
      letter-spacing: 0.02em; }
    #landingPage .banner .banner_right .banner_slogan h2 {
      max-width: 41.5vw;
      color: #fff;
      font-size: 1.6vw;
      font-weight: normal;
      margin-top: 36px; }
  #landingPage .banner .banner_right .banner_enginner {
    position: relative; }
    #landingPage .banner .banner_right .banner_enginner .dataline {
      width: 22vw;
      height: 16vw;
      position: absolute;
      z-index: 1000;
      top: 16.5vw;
      left: -4vw;
      background-image: url("../../images/landingPage/dataline_desktop.png");
      background-size: 100%;
      background-repeat: no-repeat; }
    #landingPage .banner .banner_right .banner_enginner .enginner {
      position: absolute;
      z-index: 1000;
      top: 2.75vw;
      left: 15vw; }
      #landingPage .banner .banner_right .banner_enginner .enginner img {
        width: 17.7vw; }
    #landingPage .banner .banner_right .banner_enginner .bubbleMorph .bubble_fir {
      position: absolute;
      top: 2.75vw;
      left: -2vw;
      transform: rotate(110deg); }
    #landingPage .banner .banner_right .banner_enginner .bubbleMorph .bubble_sec {
      position: absolute;
      top: 5vw;
      left: 15vw;
      transform: rotate(20deg); }

#landingPage .landingPage_contents_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 100px; }
  #landingPage .landingPage_contents_block .contents_title {
    display: flex;
    flex-direction: column;
    align-items: center; }
    #landingPage .landingPage_contents_block .contents_title h3 {
      font-size: 18px;
      font-weight: 600;
      color: #000000; }
    #landingPage .landingPage_contents_block .contents_title h4 {
      font-size: 16px;
      font-weight: normal;
      color: #000000;
      margin: 16px 0 40px; }
  #landingPage .landingPage_contents_block .contents {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    #landingPage .landingPage_contents_block .contents .content {
      max-width: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 36px 75px; }
      #landingPage .landingPage_contents_block .contents .content h4 {
        font-size: 16px;
        font-weight: normal;
        color: #000000;
        margin: 35px 0 9px; }
      #landingPage .landingPage_contents_block .contents .content p {
        font-size: 16px;
        color: #000000;
        text-align: justify; }

#landingPage .getManual_block {
  width: 100vw;
  background-image: linear-gradient(to left, #245c7b, #11455c);
  position: relative; }
  #landingPage .getManual_block .getManual_bg_first {
    width: 100%;
    height: 110%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../images/landingPage/derec_CTA01.png") -10px -13px/auto 100% no-repeat; }
  #landingPage .getManual_block .getManual_bg_sec {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 60px;
    overflow: hidden; }
    #landingPage .getManual_block .getManual_bg_sec .chart_circle_b {
      position: relative; }
      #landingPage .getManual_block .getManual_bg_sec .chart_circle_b::before {
        content: "";
        width: 250px;
        height: 250px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: -40px;
        left: 0px; }
      #landingPage .getManual_block .getManual_bg_sec .chart_circle_b::after {
        content: "";
        width: 220px;
        height: 220px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        left: 0px; }
    #landingPage .getManual_block .getManual_bg_sec .chart_circle {
      position: relative; }
      #landingPage .getManual_block .getManual_bg_sec .chart_circle::before {
        content: "";
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        top: 135px;
        right: 50px;
        background-color: rgba(255, 255, 255, 0.2); }
      #landingPage .getManual_block .getManual_bg_sec .chart_circle::after {
        content: "";
        width: 19px;
        height: 19px;
        border-radius: 50%;
        position: absolute;
        top: 135px;
        right: 15px;
        background-color: rgba(255, 255, 255, 0.2); }
  #landingPage .getManual_block .getManual_info {
    position: relative;
    width: 56vw;
    margin-left: 27vw;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 60px 60px; }
    #landingPage .getManual_block .getManual_info h4 {
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 15px; }
    #landingPage .getManual_block .getManual_info p {
      font-size: 16px;
      color: #ffffff;
      margin: 5px 0; }
    #landingPage .getManual_block .getManual_info .getManual_email {
      width: 52vw;
      margin-top: 15px; }
      #landingPage .getManual_block .getManual_info .getManual_email input {
        border: none;
        font-size: 16px;
        color: #ffffff; }
        #landingPage .getManual_block .getManual_info .getManual_email input::placeholder {
          color: rgba(255, 255, 255, 0.7); }
        #landingPage .getManual_block .getManual_info .getManual_email input:focus {
          outline: none; }
      #landingPage .getManual_block .getManual_info .getManual_email input[type="text"] {
        width: calc(52vw - 180px);
        height: 60px;
        border-bottom: 1px solid #fff;
        background-color: transparent;
        padding: 0 10px; }
      #landingPage .getManual_block .getManual_info .getManual_email input[type="button"] {
        width: 150px;
        height: 60px;
        background-color: #0f9ec7;
        cursor: pointer; }
        #landingPage .getManual_block .getManual_info .getManual_email input[type="button"]:hover {
          background-color: #14baea; }

#landingPage .qa {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 0; }
  #landingPage .qa .qa_info {
    width: 700px;
    text-align: center; }
    #landingPage .qa .qa_info h4 {
      font-size: 18px;
      font-weight: 600;
      color: #000000; }
    #landingPage .qa .qa_info p {
      font-size: 16px;
      color: #000000;
      margin: 16px 0 34px; }
  #landingPage .qa .qa_evaluation {
    width: 630px;
    height: 250px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 25px 35px;
    position: relative;
    z-index: 1000; }
    #landingPage .qa .qa_evaluation img {
      width: 250px; }
    #landingPage .qa .qa_evaluation .qa_evaluation_info {
      width: 300px;
      margin-left: 40px; }
      #landingPage .qa .qa_evaluation .qa_evaluation_info h4 {
        font-size: 18px;
        font-weight: 500;
        color: #000000; }
      #landingPage .qa .qa_evaluation .qa_evaluation_info p {
        font-size: 16px;
        color: #000000;
        margin: 10px 0; }

#landingPage .person_block {
  width: 100vw;
  background-image: linear-gradient(to left, #245c7b, #11455c);
  display: flex;
  justify-content: center;
  padding: 130px 0 80px;
  margin-top: -70px;
  position: relative; }
  #landingPage .person_block img {
    width: 110px;
    height: 110px; }
  #landingPage .person_block .person_info {
    width: 480px;
    display: flex;
    flex-direction: column;
    margin-left: 30px; }
    #landingPage .person_block .person_info p {
      font-size: 1.6vw;
      color: #ffffff;
      margin: 15px 0; }
    #landingPage .person_block .person_info .person_info_iosdev img {
      width: 50px;
      height: 20px;
      margin-left: -5px; }
    #landingPage .person_block .person_info .person_info_btn input[type="button"] {
      font-size: 16px;
      color: #fff;
      border: 1px solid #fff;
      background-color: transparent;
      padding: 20px 25px;
      margin-right: 10px;
      cursor: pointer; }
      #landingPage .person_block .person_info .person_info_btn input[type="button"]:hover {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2)); }
      #landingPage .person_block .person_info .person_info_btn input[type="button"]:first-child {
        border: none;
        background-color: #0f9ec7; }
        #landingPage .person_block .person_info .person_info_btn input[type="button"]:first-child:hover {
          background-color: #14baea; }
  #landingPage .person_block .bubbleMorph .bubble_fir {
    position: absolute;
    z-index: -100;
    top: -180px;
    left: -50px; }
  #landingPage .person_block .bubbleMorph .bubble_sec {
    position: absolute;
    z-index: -100;
    top: -370px;
    left: 35vw;
    transform: rotate(100deg); }

@media all and (max-width: 767px) {
  #landingPage .banner {
    padding: 50px 25px 0 25px;
    margin: -1px 0 0;
    background-image: none;
    flex-direction: column-reverse;
    align-items: center; }
    #landingPage .banner .app_screenshoot {
      margin-top: -240px;
      z-index: 3000; }
      #landingPage .banner .app_screenshoot .app_screenshoot_bg img {
        width: 90vw; }
      #landingPage .banner .app_screenshoot .app_screenshoot_map {
        width: 75vw;
        left: 7.5vw; }
    #landingPage .banner .banner_right {
      width: 100vw;
      background-color: #0c384c;
      padding: 100px 0 150px; }
      #landingPage .banner .banner_right .banner_slogan {
        margin: 0;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        #landingPage .banner .banner_right .banner_slogan h1 {
          font-size: 45px; }
        #landingPage .banner .banner_right .banner_slogan h2 {
          max-width: 72vw;
          text-align: justify;
          font-size: 16px;
          font-weight: normal; }
        #landingPage .banner .banner_right .banner_slogan img {
          width: 240px; }
      #landingPage .banner .banner_right .banner_enginner {
        display: flex;
        justify-content: center; }
        #landingPage .banner .banner_right .banner_enginner .dataline {
          width: 17.5vw;
          height: 30vw;
          top: auto;
          bottom: 18vw;
          left: 14vw;
          background-image: url("../../images/landingPage/dataline_mobile.png"); }
        #landingPage .banner .banner_right .banner_enginner .enginner {
          position: static;
          margin-top: 20px; }
          #landingPage .banner .banner_right .banner_enginner .enginner img {
            width: 55vw; }
        #landingPage .banner .banner_right .banner_enginner .bubbleMorph {
          width: 100vw;
          height: 70vh;
          position: absolute;
          overflow: hidden; }
          #landingPage .banner .banner_right .banner_enginner .bubbleMorph .bubble_fir {
            display: none; }
          #landingPage .banner .banner_right .banner_enginner .bubbleMorph .bubble_sec {
            transform: scale(3.5) rotate(10deg);
            top: 50vw;
            left: 35vw; }
  #landingPage .landingPage_contents_block {
    padding: 70px 30px; }
    #landingPage .landingPage_contents_block .contents_title h4 {
      text-align: justify;
      margin-bottom: 4px; }
    #landingPage .landingPage_contents_block .contents .content img {
      height: 100px; }
    #landingPage .landingPage_contents_block .contents .content p {
      width: 50vw; }
  #landingPage .getManual_block {
    height: auto;
    background-image: linear-gradient(to left, #245c7b, #11455c); }
    #landingPage .getManual_block .getManual_info {
      width: 85vw;
      margin: 0;
      padding: 40px 7vw 100px; }
      #landingPage .getManual_block .getManual_info p {
        text-align: justify; }
      #landingPage .getManual_block .getManual_info .getManual_email {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center; }
        #landingPage .getManual_block .getManual_info .getManual_email input[type="text"] {
          width: 90%;
          padding: 0 5px; }
        #landingPage .getManual_block .getManual_info .getManual_email input[type="button"] {
          position: static;
          margin-top: 30px; }
    #landingPage .getManual_block .getManual_bg_first,
    #landingPage .getManual_block .getManual_bg_sec {
      display: none; }
  #landingPage .qa {
    padding-top: 70px; }
    #landingPage .qa .qa_info {
      width: 81vw; }
      #landingPage .qa .qa_info p {
        text-align: justify; }
        #landingPage .qa .qa_info p br {
          display: none; }
    #landingPage .qa .qa_evaluation {
      width: 80vw;
      height: auto;
      flex-direction: column;
      padding: 25px; }
      #landingPage .qa .qa_evaluation img {
        width: 220px; }
      #landingPage .qa .qa_evaluation .qa_evaluation_info {
        width: 78vw;
        margin: 25px 0; }
        #landingPage .qa .qa_evaluation .qa_evaluation_info p {
          text-align: justify; }
  #landingPage .person_block {
    flex-direction: column;
    align-items: center; }
    #landingPage .person_block .person_info {
      width: 70vw;
      align-items: center;
      margin: 0; }
      #landingPage .person_block .person_info p {
        font-size: 14px;
        text-align: justify; }
      #landingPage .person_block .person_info .person_info_iosdev {
        margin-top: 10px; }
      #landingPage .person_block .person_info .person_info_btn {
        display: flex;
        flex-direction: column;
        align-items: center; }
        #landingPage .person_block .person_info .person_info_btn input[type="button"] {
          width: 50vw;
          font-size: 14px;
          padding: 15px 0;
          margin: 5px 0; }
    #landingPage .person_block .bubbleMorph {
      display: none; } }
