#header,
#header_ham {
  display: flex;
  align-items: center;
  padding: 20px 0 0 90px;
  position: relative; }
  #header .logo,
  #header_ham .logo {
    margin: 0 40px; }
    #header .logo img,
    #header_ham .logo img {
      width: 60px; }
  #header .icn_ham,
  #header_ham .icn_ham {
    display: none; }
  #header .menu ul,
  #header_ham .menu ul {
    display: flex; }
    #header .menu ul li,
    #header_ham .menu ul li {
      font-size: 16px;
      color: #ffffff;
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer; }
      #header .menu ul li:hover span,
      #header_ham .menu ul li:hover span {
        padding: 5px 0;
        border-bottom: 1px solid #fff;
        cursor: pointer; }
      #header .menu ul li:last-child span,
      #header_ham .menu ul li:last-child span {
        border: 1px solid #ffffff;
        padding: 11px 18px;
        border-radius: 2px; }
        #header .menu ul li:last-child span:hover,
        #header_ham .menu ul li:last-child span:hover {
          background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2)); }
  #header .chart_circle,
  #header_ham .chart_circle {
    width: 165px;
    height: 200px;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden; }
    #header .chart_circle .chart_circle_b,
    #header_ham .chart_circle .chart_circle_b {
      position: relative; }
      #header .chart_circle .chart_circle_b::before,
      #header_ham .chart_circle .chart_circle_b::before {
        content: "";
        width: 250px;
        height: 250px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: -130px;
        right: -136px; }
      #header .chart_circle .chart_circle_b::after,
      #header_ham .chart_circle .chart_circle_b::after {
        content: "";
        width: 320px;
        height: 320px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: -170px;
        right: -160px; }
    #header .chart_circle .chart_circle,
    #header_ham .chart_circle .chart_circle {
      position: relative; }
      #header .chart_circle .chart_circle::before,
      #header_ham .chart_circle .chart_circle::before {
        content: "";
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        top: 135px;
        right: 20px;
        background-color: rgba(255, 255, 255, 0.2); }
      #header .chart_circle .chart_circle::after,
      #header_ham .chart_circle .chart_circle::after {
        content: "";
        width: 19px;
        height: 19px;
        border-radius: 50%;
        position: absolute;
        top: 180px;
        right: 42px;
        background-color: rgba(255, 255, 255, 0.2); }

@media all and (max-width: 767px) {
  #header {
    width: 100vw;
    background-color: #0c384c;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0; }
    #header .logo {
      margin: 0; }
      #header .logo img {
        width: 30px; }
    #header .icn_ham {
      display: none; }
    #header .menu ul li {
      margin: 0 6px;
      font-size: 12px; }
    #header .chart_circle {
      display: none; }
  #header_ham {
    width: 100vw;
    height: 20px;
    background-color: #0c384c;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 10000; }
    #header_ham .logo {
      margin: 0; }
      #header_ham .logo img {
        width: 30px; }
    #header_ham .icn_ham {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0px;
      right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      #header_ham .icn_ham img {
        width: 14px; }
    #header_ham .menu {
      position: absolute;
      top: 59px;
      right: -250px;
      background-color: #0c384c;
      transition: 0.5s; }
      #header_ham .menu ul {
        flex-direction: column; }
        #header_ham .menu ul li {
          width: 120px;
          height: 50px;
          font-size: 14px;
          margin: 0; }
          #header_ham .menu ul li span:last-child {
            padding: 4px 10px; }
    #header_ham .menu-open {
      right: 40px; }
    #header_ham .chart_circle {
      display: none; } }
